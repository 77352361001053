<template>
  <div class="page_header">
    <a-breadcrumb>
      <a-breadcrumb-item v-for="(item,index) in pageData" :key="index">
        <router-link v-if="item.path!=''" :to="item.path">{{item.breadcrumbName }}</router-link>
        <span v-else>{{item.breadcrumbName }}</span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script>
export default {
  name:'page'
  ,data(){
     return{
    }
  },
  props:['details'],
  computed:{
    pageData(){
      let obj=[{
          breadcrumbName: '授课证',
          path:"/VCertificate"
        }]
        if(this.details!=null){
          obj.push({'breadcrumbName':this.details.courseName,'path':''});
        }
      return obj;
    }
  },
}
</script>
<style lang="scss" scoped>
.page_header{
  width: 1200px;
  margin: 0 auto;
  padding-top: 25px;
}
</style>